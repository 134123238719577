import { Image, Text, Outlines, Effects } from "@react-three/drei";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { animate, useMotionValue } from "framer-motion";

import { motion } from "framer-motion-3d";
import { atom, useAtom } from "jotai";
import { useEffect, useState, useRef } from "react";




export const projects = [
  {
    title: "3D Web3 Dapp",
    url: "https://stake.dragon3d.app",
    image: "images/stake.jpg",
    description: `A staking dapp built inside of a React Three Fiber app, with first person controls. Move around and double click or tap the wizard a few times inside the dojo. Connects on chain to a solidity contract with a fully functioning dapp. \n\n [React] [R3F] [Web3 SDKs] [Solidity]`,
  },
  {
    title: "3D Website",
    url: "https://dragon3d.app",
    image: "images/website.jpg",
    description: `A 3D landing page for a Web3 ecosystem built with React Three Fiber. Orbit around the island or zoom in to various landmarks / characters to provide a unique navigation experience. \n\n [JavaScript] [React] [R3F] [Threejs]`
  },
  {
    title: "3D AI Assistant",
    url: "https://assistant.forbiddenforge.dev",
    image: "images/assistant.jpg",
    description: `App integrating 3D avatar, real-time chat, and audio features. Utilizes React, React Three Fiber, Three.js, WebSockets for the frontend, and Express.js, BullMQ, Redis, and WebSockets for the backend. The app ensures a seamless user experience with real-time communication and efficient data handling. \n\n[R3F] [Websockets] [Nodejs] [Express] [BullMQ] [Redis] [AI]`,
  },
  {
    title: "Xrocket",
    url: "https://github.com/ForbiddenForge/Xrocket",
    image: "images/xrocket.jpg",
    description: `Xrocket is a light-weight rocket simulator. Allows users to visualize rocket data and save plots for analysis. \n\n [Python] [Numpy] [Matplotlib]`,
  },
];

// Create atom states to be used across the Canvas project components and accessible
// to the overlying Html UI elements
export const currentProjectIndexAtom = atom(Math.floor(projects.length / 2));
export const projectOpenedAtom = atom(false);


/**
 * Display container for each individual project
*/
const ProjectContainer = (props) => {
  const { project, highlighted } = props;
  const [isHovering, setIsHovering] = useState(false);
  const [projectOpened, setProjectOpened] = useAtom(projectOpenedAtom);
  const projectGroup = useRef();
  const backgroundRef = useRef();
  const bgOpacity = useMotionValue(0.4);



  useEffect(() => {
    animate(bgOpacity, highlighted ? 0.9 : 0.4);
  }, [highlighted]);

  useFrame(() => {
    backgroundRef.current.material.opacity = bgOpacity.get();
  });

  const handleProjectContainerClick = () => {
    if (!projectOpened && highlighted) {
      setProjectOpened(true);
    }
  }


  return (
    <group rotation-x={0} ref={projectGroup} {...props} style={{AnimationEffect: 'animate-bounce'}}>
      <mesh 
        ref={backgroundRef}
        position-z={-0.0001}
        onClick={handleProjectContainerClick}
        onPointerOver={() => {
          setIsHovering(true);
          if (highlighted) {
            document.body.style.cursor = 'pointer';
          }
        }}
        onPointerOut={() => {
          setIsHovering(false);
          document.body.style.cursor = 'auto';
        }}
      >
        <planeGeometry args={[2.2, 3.2]} />
        <meshStandardMaterial color={highlighted ? "#7a2bca" : "#000000"} />
        {highlighted && isHovering && (
          <>
            <mesh position={[0, 0, -0.01]} scale={[1.05, 1.05, 1.05]}>
            <planeGeometry args={[2.2, 3.2]} />
            <meshBasicMaterial color="white" />
            </mesh>
            <mesh position={[0, 0, -0.02]} scale={[1.1, 1.1, 1.1]}>
            <planeGeometry args={[2.2, 3.2]} />
            <meshBasicMaterial color="black" />
            </mesh>
          </>
        )}
      </mesh>
      <Image
        scale={[2, 1.3, 1]}
        url={project.image}
        toneMapped={false}
        position-y={0.88}
      />
      <Text
        color={"white"}
        maxWidth={2}
        anchorX={"left"}
        anchorY={"top"}
        fontSize={0.2}
        position={[-1, 0.2, 0]}
      >
        {project.title.toUpperCase()}
      </Text>
      <Text
        maxWidth={2}
        anchorX="left"
        anchorY="top"
        fontSize={0.1}
        position={[-1, -0.05, 0]}
      >
        {project.description}
      </Text>
    </group>
  );
};



export default function Projects (props) {

  const { viewport } = useThree()
  const [currentProject] = useAtom(currentProjectIndexAtom); 

  return (
    <group position-y={-viewport.height * 2 - 2 }>
      {
        projects.map((project, index) => (
          <motion.group 
            key={"project_" + index} 
            position={[index * 2.5, 0, -2]}
            animate={{
              x: 0 + (index - currentProject) * 2.5,
              y: currentProject === index ? 2 : 0.8,
              z: currentProject === index ? -1.5 : -3,
              rotateX: currentProject === index ? 0 : -Math.PI / 3,
              rotateZ: currentProject === index ? 0 : -0.1 * Math.PI,
            }}
          
          >
            <ProjectContainer project={project} highlighted={index === currentProject}/>
          </motion.group>
        ))
      }
    </group>
  )
}


const Menu = (props) => {
  const { setSection, menuOpened, setMenuOpened } = props


  return (
    <>
      <button
      onClick={() => {setMenuOpened(!menuOpened)}} 
      className="z-20 fixed top-12 right-12 p-3 bg-indigo-600 w-11 h-11 rounded-md"
      >
        <div 
          className={`bg-white h-0.5 rounded-md w-full transition-all ${menuOpened ? "rotate-45 translate-y-0.5" : ""}`}
        />
        <div
          className={`bg-white h-0.5 rounded-md w-full my-1 ${menuOpened ? "hidden" : ""}`}
        />
        <div 
          className={`bg-white h-0.5 rounded-md w-full transition-all ${menuOpened ? "-rotate-45" : ""}`}
        />
      </button>

      <div 
        className={`z-10 fixed top-0 right-0 bottom-0 bg-neutral-200 transition-all overflow-hidden flex col ${menuOpened ? "w-60" : "w-0"}`}
      >
        <div className="flex-1 flex items-center justify-center flex-col gap-6 p-8">
          <MenuButton label="About" onClick={() => setSection(0)} />
          <div className="bg-indigo-600 h-0.5 rounded-md w-1/3" />
          <MenuButton label="Skills" onClick={() => setSection(1)} />
          <div className="bg-indigo-600 h-0.5 rounded-md w-1/3" />
          <MenuButton label="Projects" onClick={() => setSection(2)} />
          <div className="bg-indigo-600 h-0.5 rounded-md w-1/3" />
          <MenuButton label="Contact" onClick={() => setSection(3)} />
          <div className="bg-indigo-600 h-0.5 rounded-md w-1/3" />
        </div>
      </div>
    </>
  )
}
export default Menu




const MenuButton = (props) => {
  const { label, onClick } = props
  return (
    <button
      onClick={onClick}
      className="text-2xl font-bold cursor-pointer text-slate-400 hover:text-indigo-600 transition-colors"
    >
      {label}
    </button>

  )
}





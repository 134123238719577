import { motion } from 'framer-motion';

const skills = [
  {
    title: "Threejs / React Three Fiber",
    level: 80
  },
  {
    title: "React",
    level: 90
  },
  {
    title: "Nodejs",
    level: 90
  },
  {
    title: "Python",
    level: 80
  },
]

const languages = [
  {
    title: "English ",
    level: 100
  },
  {
    title: "Polish",
    level: 90
  },
  {
    title: "Mandarin",
    level: 75
  },
]


const SkillsSection = () => {

  return (
    <>
      <motion.div whileInView={"visible"}>
        <h2 className="text-5xl font-bold">Skills</h2>
        <motion.div className="mt-8 space-y-4">
          {skills.map((skill, index) =>(
            <div 
              className="w-64" 
              key={index}
              initial={{
                scale: 1,
              }}
              
              transition={{
                duration: 0.5,
                delay: 0.1,
                type: "spring",
                stiffness: 50,
              }}
            >

              <motion.h3 
                className="text-xl font-bold text-gray-800"
                initial={{
                  opacity: 0,
                  scale: 1,
                }}
                variants={{
                  visible: {
                    opacity: 1,
                    transition: {
                      duration: 1,
                      delay: 1 + index * 0.2
                    }
                  }
                }}
                whileHover={{
                  scale: 1.1,
                  transition: {
                    duration: 0.2,
                    delay: 0.1,
                    type: "spring",
                    stiffness: 500,
                  }
                }}
                transition={{
                  duration: 1,
                  delay: 1 + index * 0.2,
                }}
              >
                {skill.title}
              </motion.h3>

              <div className="h-2 w-full bg-gray-400 rounded-full mt-2">

                <motion.div 
                  className="h-full bg-indigo-500 rounded-full"
                  style={{ width: `${skill.level}%` }}
                  initial={{
                    scaleX: 0,
                    originX: 0,
                  }}
                  variants={{
                    visible: {
                      scaleX: 1,
                      transition: {
                        duration: 1,
                        delay: 1 + index * 0.2
                      }
                    }
                  }}
                  transition={{
                    duration: 1,
                    delay: 1 + index * 0.2
                  }}
                />
              </div>
            </div>
          ))}
        </motion.div>
      </motion.div>

      <motion.div whileInView={"visible"}>
        <h2 className="text-5xl font-bold mt-16">Languages</h2>
        <div className="mt-8 space-y-4">
          {languages.map((language, index) =>(
            <div className="w-64" key={index}>
              <motion.h3 
                className="text-xl font-bold text-gray-800"
                initial={{
                  opacity: 0,
                }}
                variants={{
                  visible: {
                    opacity: 1,
                    transition: {
                      duration: 1,
                      delay: 1 + index * 0.2
                    }
                  }
                }}
                whileHover={{
                  scale: 1.1,
                  transition: {
                    duration: 0.2,
                    delay: 0.1,
                    type: "spring",
                    stiffness: 500,
                  }
                }}
                transition={{
                  duration: 1,
                  delay: 1 + index * 0.2,
                }}
              >
                {language.title}
              </motion.h3>
              <div className="h-2 w-full bg-gray-400 rounded-full mt-2">
                <motion.div 
                  className="h-full bg-indigo-500 rounded-full"
                  style={{ width: `${language.level}%` }}
                  initial={{
                    scaleX: 0,
                    originX: 0,
                  }}
                  variants={{
                    visible: {
                      scaleX: 1,
                      transition: {
                        duration: 1,
                        delay: 1 + index * 0.2
                      }
                    }
                  }}
                  transition={{
                    duration: 1,
                    delay: 1 + index * 0.2
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </motion.div>

    </>
  )
};

export default SkillsSection
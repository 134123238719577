import { Environment, Loader, OrbitControls, Sky, useScroll } from "@react-three/drei";
import { Suspense, useEffect, useRef, useState } from "react";

import { AmbientLight } from "three";
import { motion } from "framer-motion-3d";
import { useMotionValue, animate } from "framer-motion";
import { useFrame, useThree } from "@react-three/fiber";

import * as THREE from 'three'

import { framerMotionConfig } from "../config.js";
import Scene from "./Scene";
import  Avatar  from "./Avatar";
import Projects from "./Projects"
import Background from "./Background.jsx";

export default function Experience (props) {

  const { menuOpened } = props
  
  const scrollData = useScroll()
  const { viewport } = useThree() 
  
  const [ section, setSection ] = useState(0)
  
  
  
  const cameraPositionX = useMotionValue()
  const cameraLookAtX = useMotionValue()
  
  const [ animation, setAnimation ] = useState("Typing")
  const avatarRef = useRef();


  // Available animations are: Waving, FancyHipHopDance, Typing, Talking, ThumbsUp, Praying, LookingBehind, Looking, Laughing, HappyIdle, Idle, HipHopDancing, Falling, Disappointed, Counting, Arguing, Angry

  useEffect(() => {
    if (section > 0) {
      setAnimation("Falling")
    }
    setTimeout(() => {
        switch(section) {
          case 0:
            setAnimation("Typing")
            break
          case 1:
            setAnimation("FancyHipHopDance")
            break
          case 2:
            setAnimation("HappyIdle")
            break
          case 3:
            setAnimation("Waving")
            break
        }
      }, 600)
    }, [section])


  useEffect(() => {
    animate(cameraPositionX, menuOpened ? -5 : 0, {...framerMotionConfig})
    animate(cameraLookAtX, menuOpened ? 5 : 0, {...framerMotionConfig})
  }, [menuOpened])



  useFrame((state) => {
    const currentSection = Math.floor(scrollData.offset * scrollData.pages)
    if (currentSection !== section && currentSection < 4) {
      setSection(currentSection)
    } else if (currentSection >= 4) {
      setSection(3)
    }

    

    state.camera.position.x = cameraPositionX.get()
    state.camera.lookAt(cameraLookAtX.get(), 0, 0)



    // const avatarPosition = new THREE.Vector3()
    // avatarRef.current.getWorldPosition(avatarPosition)

    // const quaternion = new THREE.Quaternion()
    // avatarRef.current.getWorldPosition(quaternion)
    // const euler = new THREE.Euler()
    // euler.setFromQuaternion(quaternion, "XYZ")
  })

  return (
    <>

      {/* <OrbitControls /> */}
      <Background />
      <Sky />
      {/* <ambientLight intensity={1}/> */}
      <Environment preset="sunset" />
      <motion.group>

        <Scene section={section} />

        
      </motion.group>

      <motion.group 
        ref={avatarRef}
        scale={1.05} 
        position={[0.58, -0.77, 0.33]} 
        rotation={[0, 1.4, 0]} 
        animate={"" + section}
        transition={{
          duration: 0.6
        }}
        variants={{
          0: {
            scaleX: 1,
            scaleY: 1,
            scaleZ: 1,
          },
          1: {
            scaleX: 0.8,
            scaleY: 0.8,
            scaleZ: 0.8,
            y: -viewport.height - 0.6,
            x: 0,
            z: 0,
            rotateX: 0,
            rotateY: 0,
            rotateZ: 0,
          },
          2: {
            scaleX: 0.8,
            scaleY: 0.8,
            scaleZ: 0.8,
            y: -viewport.height * 1.8 - 2,
            x: -viewport.width / 4,
            z: -1,
            rotateX: 0,
            rotateY: 2.1,
            rotateZ: 0,
          },
          3: {
            scaleX: 1,
            scaleY: 1,
            scaleZ: 1,
            y: -viewport.height * 3 - 1,
            x: viewport.width / 3,
            z: -1.5,
            rotateX: 0,
            rotateY: -Math.PI/8,
            rotateZ: 0,
          }
        }}
        >

  
        <Avatar
          animation={animation} 
          setAnimation={setAnimation} 
          />
      </motion.group> 

      <Projects />
      

    </>
  );
};

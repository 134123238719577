import { motion, AnimatePresence } from "framer-motion"

import { useState } from 'react';
import Section from './interface/Section.jsx'
import AboutSection from './interface/AboutSection.jsx'
import SkillsSection from './interface/SkillsSection.jsx'
import ProjectsSection from './interface/ProjectsSection.jsx'
import ContactSection from './interface/ContactSection.jsx'





export default function Interface(props) {
  const { setSection } = props
  return (
    <>
      <div className="flex flex-col items-center w-screen">
        <Section>
          <AboutSection setSection={setSection} />
        </Section>

        <Section>
          <SkillsSection />
        </Section>

        <Section>
          <ProjectsSection />
        </Section>

        <Section>
          <ContactSection />
        </Section>
      
      </div>
    </>
  )
};





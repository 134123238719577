import React, { useEffect, useRef } from 'react';
import emailjs from 'emailjs-com';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

const ContactSection = () => {
  const captchaRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (captchaRef.current) {
        loadCaptchaEnginge(6);
      }
    }, 5000); // 500ms delay, adjust if necessary

    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const userCaptchaValue = document.getElementById('user_captcha_input').value;

    if (validateCaptcha(userCaptchaValue)) {
      emailjs.sendForm(
        import.meta.env.VITE_EMAILJS_SERVICE_ID,
        import.meta.env.VITE_EMAILJS_TEMPLATE_ID,
        form,
        import.meta.env.VITE_EMAILJS_PUBLIC
      ).then((result) => {
        console.log(result.text);
        alert('Message sent successfully!');
      }, (error) => {
        console.log(error.text);
        alert('Failed to send the message, please try again.');
      });

      // Clear the form and reload captcha
      form.reset();
      loadCaptchaEnginge(6);
      document.getElementById('user_captcha_input').value = '';
    } else {
      alert('Invalid CAPTCHA');
      document.getElementById('user_captcha_input').value = '';
    }
  };

  return (
    <div className='relative mt-3.5 mx-auto z-100'>
      <h2 className="text-5xl font-bold z-20">Contact me</h2>
      <div className="mt-8 p-8 rounded-md w-96 max-w-full relative" style={{ zIndex: 10 }}>
        <div className="absolute inset-0 bg-white opacity-20 rounded-md"></div>
        <form onSubmit={handleSubmit} className="relative z-10">
          <label htmlFor="name" className="font-medium text-gray-900 block mb-1">
            Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 p-3"
            required
          />
          <label
            htmlFor="email"
            className="font-medium text-gray-900 block mb-1 mt-8"
          >
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 p-3"
            required
          />
          <label
            htmlFor="message"
            className="font-medium text-gray-900 block mb-1 mt-8"
          >
            Message
          </label>
          <textarea
            name="message"
            id="message"
            className="h-32 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 p-3"
            required
          />
          <div className="mt-8" ref={captchaRef}>
            <LoadCanvasTemplate />
            <input
              placeholder="Enter Captcha Value"
              id="user_captcha_input"
              name="user_captcha_input"
              type="text"
              className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 p-3 mt-4"
              required
            />
          </div>
          <button className="bg-indigo-600 text-white py-4 px-8 rounded-lg font-bold text-lg mt-16">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactSection;

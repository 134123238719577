
import { atom, useAtom } from "jotai";
import { currentProjectIndexAtom, projectOpenedAtom, projects } from "../Projects";
import { motion } from "framer-motion";
import { useState } from "react";




const ProjectPopup = ({onClose, currentProjectInfo, ...props}) => {
  const [hasCopied, setHasCopied] = useState(false);


  const handleProjectClick = () => {
      window.open(currentProjectInfo.url, "_blank");
    }


  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(currentProjectInfo.url).then(() => {
      setHasCopied(true);
      setTimeout(() => setHasCopied(false), 2000);
    }, (err) => {
      console.error('Failed to copy email', err);
    });
  };

  // close window if the modal-overlay wrapper detects a click
  // stop propagation inside the popup in the return div below
  const handleOutsideClick = (e) => {
    if (e.target.id === "modal-overlay") {
      onClose();
    }
  };


  return (
    <div
      id="modal-overlay" 
      className="fixed left-0 right-0 w-full h-full bg-gray-600 bg-opacity-50 z-20 p-3 rounded-md flex flex-col justify-center items-center"
      onClick={handleOutsideClick} 
    >
      <div 
        className="bg-gradient-to-r from-indigo-300 via-purple-100 to-pink-300 p-6 rounded-lg shadow-lg flex flex-col items-center gap-4 max-w-sm"
        onClick={(e) => e.stopPropagation() }
      >
        <p className="mb-1 text-xl font-extrabold underline">{currentProjectInfo.title}</p>
        <img className="max-w-sm" src={currentProjectInfo.image} alt="" />
        <p className="mb-4 text-md ">{currentProjectInfo.description}</p>
        <button
          onClick={() => handleProjectClick()}
          className={`text-sm py-2 px-4 rounded transition bg-indigo-600 hover:bg-indigo-700 text-white`}
        >
          Open Project in New Window
        </button>
        <button
          onClick={() => handleCopyToClipboard()}
          className={`text-sm py-2 px-4 rounded transition ${hasCopied ? 'bg-green-500 text-white' : 'bg-indigo-600 hover:bg-indigo-700 text-white'}`}
        >
          {hasCopied ? (
            <>
              Copied
              <svg className="inline-block w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M13.854 3.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L6.5 10.293l6.646-6.647a.5.5 0 01.708 0z" />
              </svg>
            </>
          ) : 'Copy Project Link'}
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          className="text-sm bg-slate-300 text-gray-700 font-extrabold py-2 px-4 rounded hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition"
        >
          Close
        </button>
      </div>
    </div>
  );
};


const ProjectsSection = () => {
  const [currentProjectIndex, setCurrentProjectIndex] = useAtom(currentProjectIndexAtom);
  const [ projectOpened, setProjectOpened ] = useAtom(projectOpenedAtom);

  const currentProjectInfo = projects[currentProjectIndex]

  const nextProject = () => {
    setCurrentProjectIndex((currentProjectIndex + 1) % projects.length);
    setProjectOpened(false)
  };

  const previousProject = () => {
    setCurrentProjectIndex((currentProjectIndex - 1 + projects.length) % projects.length);
    setProjectOpened(false)
  };

  const onClose = () => {
    setProjectOpened(false);
  }
  console.log('project opened?', projectOpened)

  return (
    <>
      {projectOpened && <ProjectPopup onClose={onClose} currentProjectInfo={currentProjectInfo} />}
      <div className="fixed left-0 right-0 flex w-full h-full gap-8 items-center justify-center translate-y-60">
        <div className="flex flex-col gap-3">

          <div className="flex gap-8">
            <button
              className="hover:text-white hover:bg-indigo-600 transition-colors bg-white rounded-xl py-2 px-3"
              onClick={() => previousProject()}
            >
              ← Prev
            </button>
            <h2 className="text-5xl font-bold">Projects</h2>
            <button
              className="hover:text-white hover:bg-indigo-600 transition-colors bg-white rounded-xl py-2 px-3"
              onClick={() => nextProject()}
            >
              Next →
            </button>
          </div>

          <div className="flex items-center justify-center">
          <button
              className="hover:text-white hover:bg-indigo-600 transition-colors bg-white rounded-xl py-2 px-3"
              onClick={() => setProjectOpened(true)}
            >
              Project Info
          </button>
          </div>

        </div>
      </div>
    </>
  );
};

export default ProjectsSection;

import { useScroll } from "@react-three/drei"
import { useRef } from "react"
import { useFrame } from "@react-three/fiber"
import { useEffect } from "react"

const ScrollManager = (props) => {

  const { section, setSection } = props

  const scrollData = useScroll()
  const lastOffset = useRef(0)
  const isAnimating = useRef(false)

  /**
   * When useScroll creates the number of "pages" we specify,
   * it creates elements which are an incorrect scalar multiply 
   * of the user's viewport height within its CSS. 
   * It results in the elements being a little too large and causes unexpected behavior when animating to different "pages." The fix is below.
   */
  scrollData.fill.classList.add("top-0")
  scrollData.fill.classList.add("absolute")


  useEffect(() => {

    console.log('change section!')

    
  }, [section])




  useFrame(() => {
    if (isAnimating.current) {
      lastOffset.current = scrollData.offset
      return
    }

    const currentSection = Math.floor(scrollData.offset * scrollData.pages)
    if (scrollData.offset > lastOffset.current && currentSection === 0) {
      setSection(1)
      
    }

    if (scrollData.offset < lastOffset.current && scrollData.offset < 1 / (scrollData.pages - 1)) {
      setSection(0)
    }
    lastOffset.current = scrollData.offset
  })
  scrollData.el.scrollTo(0, section * scrollData.el.clientHeight)

  return (
    null
  )
}


// Edit: Change data.scroll.current to data.offset.
// And instead of using gsap just use "scrollTo". In useFrame after "onSectionChange" add "data.el.scrollTo(0, sectionNumber * data.el.clientHeight)"
export default ScrollManager
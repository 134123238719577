import { motion } from "framer-motion"

const sectionVariants = {
  hidden: {
    opacity: 0,
    y: 50,
    transition: { duration: 0.3 }
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5, delay: 0.3 }
  }
}



const Section = ({children, ...props}) => {
  

  return (
    <motion.section 
      className={`h-screen w-screen p-10 max-w-screen-2xl mx-auto flex flex-col items-start justify-center text-black`}
      variants={sectionVariants}
      initial="hidden"
      whileInView="visible"
      transition={{ type: "spring", stiffness: 100 }}  
    >
      {children}
    </motion.section>
  )
};

export default Section;
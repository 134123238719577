import { Sphere, useScroll } from "@react-three/drei";
import { useRef, useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from 'three'
import { gsap } from "gsap";

export default function Background (props) {

  const materialRef = useRef()

  const color = useRef({
    color: '#b9bcff'
  })

  const data = useScroll()

  const timeline = useRef()
  


  useEffect(() => {
    timeline.current = gsap.timeline()
    timeline.current.to(color.current, {
      color: '#e3f8fa'
    })
    timeline.current.to(color.current, {
      color: '#c3c8fa'
    })
    timeline.current.to(color.current, {
      color: '#f5e3ff'
    })
    timeline.current.to(color.current, {
      color: '#faf7e6'
    })

  }, [])

  useFrame(() => {
    timeline.current?.progress(data.scroll.current)
    materialRef.current.color = new THREE.Color(color.current.color)
  })

  return (
    <group>
      <Sphere scale={[30, 30, 30]}>
        <meshBasicMaterial ref={materialRef} side={THREE.BackSide} toneMapped={false} />
      </Sphere>
    </group>
  )
}
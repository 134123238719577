import { Canvas } from "@react-three/fiber";
import { Leva } from "leva";
import { Html, Loader, Scroll, ScrollControls } from "@react-three/drei";
import { Suspense, useState } from "react";
import { MotionConfig } from "framer-motion";

import { framerMotionConfig } from "./config.js";

import Experience  from "./components/Experience.jsx";
import Interface from "./components/Interface.jsx";
import ScrollManager from "./components/ScrollManager.jsx";
import Menu from "./components/Menu.jsx";

function App() {

  const [section, setSection] = useState(0)
  const [menuOpened, setMenuOpened] = useState(false)

  // useEffect(() => {
  //   setMenuOpened(false);
  // }, [section])


  return (
    <>
      <MotionConfig transition={{
          ...framerMotionConfig
        }}>
        <Leva hidden />
        <Canvas shadows camera={{ position: [-0.7, 0.5, 2.1]}}>
          <Suspense fallback={null}>
            <color attach="background" args={["#ececec"]} />

            <ScrollControls pages={4} damping={0.1}>
              <ScrollManager section={section} setSection={setSection} /> 
              <Scroll>
                <Experience section={section} menuOpened={menuOpened}/>
              </Scroll>
              <Scroll html>   
                <Interface setSection={setSection} />
              </Scroll>
            </ScrollControls>
          </Suspense>

        </Canvas>
        <Loader dataInterpolation={(p) => `Loading ${p.toFixed(2)}%`} />
        <Menu 
          setSection={setSection} 
          menuOpened={menuOpened} 
          setMenuOpened={setMenuOpened} 
        />
      </MotionConfig>
    </>
  );
}

export default App;

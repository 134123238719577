import { useState } from "react";
import { motion } from "framer-motion";


const EmailPopup = ({ onClose }) => {
  const email = "forbiddenforge64@gmail.com";
  const [hasCopied, setHasCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(email).then(() => {
      setHasCopied(true);
      setTimeout(() => setHasCopied(false), 2000); // Reset the button after 2 seconds
    }, (err) => {
      console.error('Failed to copy email', err);
    });
  };

  return (
    <div className="relative bg-gray-600 bg-opacity-50 z-20 p-3 mt-20 ml-20 w-11 h-11 rounded-md flex flex-col justify-center items-center">
      <div className="bg-gradient-to-r from-indigo-300 via-purple-100 to-pink-300 p-6 rounded-lg shadow-lg flex flex-col items-center">
        <p className="mb-4 text-sm">{email}</p>
        <button
          onClick={copyToClipboard}
          className={`text-sm py-2 px-4 rounded transition ${hasCopied ? 'bg-green-500 text-white' : 'bg-indigo-600 hover:bg-indigo-700 text-white'}`}
        >
          {hasCopied ? (
            <>
              Copied
              <svg className="inline-block w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M13.854 3.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L6.5 10.293l6.646-6.647a.5.5 0 01.708 0z" />
              </svg>
            </>
          ) : 'Copy Email'}
        </button>
        <button
          onClick={onClose}
          className="mt-4 text-sm bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition"
        >
          Close
        </button>
      </div>
    </div>
  );
};



const AboutSection = (props) => {

  const { setSection } = props
  const [showEmailPopup, setShowEmailPopup] = useState(false);

  const handleOpenPopup = () => {
    setShowEmailPopup(true);
  };

  const handleClosePopup = () => {
    setShowEmailPopup(false);
  };

  return (
    <>
        <h1 className="text-6xl font-extrabold leading-snug">Welcome to the
        <br />
        <span className="bg-neutral-200 rounded-xl px-3">Forbidden Forge</span>!
        </h1>
        <motion.p 
          className="text-lg text-black mt-4 bg-slate-100 rounded-xl px-3"
          initial={{
            opacity: 0,
            y: 25,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.3,
            delay: 0.5,
          }}
        >
          Nice to see you! 
        </motion.p>
        <motion.p 
          className="text-lg text-black mt-1 bg-slate-100 rounded-xl px-3"
          initial={{
            opacity: 0,
            y: 25,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.3,
            delay: 0.5,
          }}
        >
          I'm a software developer interested in Web3, AI, and 3D programming. Below you'll find some of the projects I've been working on!
        </motion.p>
        <motion.button 
          className="bg-indigo-600 text-white py-4 px-8 rounded-lg font-bold text-lg mt-8"
          onClick={handleOpenPopup}
          initial={{
            opacity: 0,
            y: 25,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          whileHover={{
            scale: 1.1,
            transition: {
              delay: 0.1,
              duration: 0.1,
            }
          }}
          transition={{
            duration: 0.3,
            delay: 0.7,
          }}
        >
          Get in Touch
        </motion.button>
        {showEmailPopup &&<EmailPopup onClose={handleClosePopup}/>}

    </>
  )
}

export default AboutSection;

import React, { useEffect, useRef, useState } from 'react'
import { useAnimations, useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useControls } from 'leva'
import * as THREE from 'three'

export default function Avatar({animation, setAnimation, ...props}) {

  
  
  const avatarRef = useRef()
  
  const { nodes, materials } = useGLTF('models/p.glb')
  const { animations } = useGLTF('models/animations/animationsMale.glb')
  // Available animations are: Waving, FancyHipHopDance, Typing, Talking, ThumbsUp, Praying, LookingBehind, Looking, Laughing, HappyIdle, Idle, HipHopDancing, Falling, Disappointed, Counting, Arguing, Angry
  
  const { actions, mixer } = useAnimations(animations, avatarRef)
  // Search through the animations array for Idle, otherwise default animation will be the 0 index

  // Lift state up
  // const [ animation, setAnimation ] = useState(animations.find((a) => a.name === "Idle") ? "Idle" : animations[0].name)


  /**
   * Leva Debug UI 
   */
  const { cameraFollow, cursorFollow, wireframe } = useControls({
    cameraFollow: false,
    cursorFollow: false,
    wireframe: false
  })

  const { animationPicker } = useControls({
    animation: {
      value: animation,
      options: animations.map(a => a.name),
      onChange: (value) => {
        setAnimation(value)
      }
      
    }
  })
  
  
  // Play Animations
  useEffect(() => {
    if (animations && actions) {
      actions[animation]
      .reset()
      .fadeIn(mixer.stats.actions.inUse === 0 ? 0 : 0.5)
      .play()
    }
    
    return () => actions[animation].reset().fadeOut(0.5)
    
  }, [animation])
  

  /**
   * Wireframe effects
   */
  useEffect(() => {
    Object.values(materials).forEach((material) => {
      material.wireframe = wireframe
    })
  }, [wireframe])
  
  /**
   * Animation Loop
   */
  useFrame((state) => {
    if (cameraFollow) {
      avatarRef.current.getObjectByName('Head').lookAt(state.camera.position)
    }
    if (cursorFollow) {
      const cursorTarget = new THREE.Vector3(state.pointer.x, state.pointer.y, 2)
      avatarRef.current.getObjectByName('Spine2').lookAt(cursorTarget)
    }
  })
  

  return (
    <group 
      ref={avatarRef}
      
      {...props} 
      
      dispose={null}
    >
      <primitive object={nodes.Hips} />
      <skinnedMesh
        name="EyeLeft"
        geometry={nodes.EyeLeft.geometry}
        material={materials.Wolf3D_Eye}
        skeleton={nodes.EyeLeft.skeleton}
        morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
        morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
        frustumCulled={false}
      />
      <skinnedMesh
        name="EyeRight"
        geometry={nodes.EyeRight.geometry}
        material={materials.Wolf3D_Eye}
        skeleton={nodes.EyeRight.skeleton}
        morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
        morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
        frustumCulled={false}
      />
      <skinnedMesh
        name="Wolf3D_Head"
        geometry={nodes.Wolf3D_Head.geometry}
        material={materials.Wolf3D_Skin}
        skeleton={nodes.Wolf3D_Head.skeleton}
        morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
        morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
        frustumCulled={false}
      />
      <skinnedMesh
        name="Wolf3D_Teeth"
        geometry={nodes.Wolf3D_Teeth.geometry}
        material={materials.Wolf3D_Teeth}
        skeleton={nodes.Wolf3D_Teeth.skeleton}
        morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
        morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
        frustumCulled={false}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Hair.geometry}
        material={materials.Wolf3D_Hair}
        skeleton={nodes.Wolf3D_Hair.skeleton}
        frustumCulled={false}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Body.geometry}
        material={materials.Wolf3D_Body}
        skeleton={nodes.Wolf3D_Body.skeleton}
        frustumCulled={false}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
        material={materials.Wolf3D_Outfit_Bottom}
        skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
        frustumCulled={false}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
        material={materials.Wolf3D_Outfit_Footwear}
        skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
        frustumCulled={false}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Top.geometry}
        material={materials.Wolf3D_Outfit_Top}
        skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
        frustumCulled={false}
      />
    </group>
  )
}

useGLTF.preload('models/p.glb')
useGLTF.preload('models/animations/animationsMale.glb')




